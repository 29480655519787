<template>
  <div>
    <p class="text-2xl mb-6">Ventas por horas</p>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-select
                  dense
                  outlined
                  v-model="filtros.tipo_fecha"
                  :items="selectTipoFecha"
                  item-value="Id"
                  item-text="Tipo"
                  label="Tipo de fecha"
                  hide-details="auto"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  dense
                  outlined
                  v-model="filtros.tipo_grupo"
                  :items="selectTipoGrupo"
                  item-value="Id"
                  item-text="Tipo"
                  label="Tipo grupo"
                  hide-details="auto"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <DatePicker
                  v-if="filtros.tipo_fecha == 1"
                  v-model="filtros.fecha_1.desde"
                  :type="'month'"
                  :dense="true"
                  :outlined="true"
                  :label="'Mes grupo 1'"
                  hide-details="auto"
                />
                <dos-fechas
                  v-if="filtros.tipo_fecha == 2"
                  :label="'Fecha Grupo 1'"
                  :fechas="[filtros.fecha_1.desde, filtros.fecha_1.hasta]"
                  @getFechas="getFechas1"
                  :icon="false"
                  hide-details="auto"
                ></dos-fechas>
              </v-col>
              <v-col cols="6">
                <DatePicker
                  v-if="filtros.tipo_fecha == 1"
                  v-model="filtros.fecha_2.desde"
                  :type="'month'"
                  :dense="true"
                  :outlined="true"
                  :label="'Mes grupo 2'"
                  hide-details="auto"
                />
                <dos-fechas
                  v-if="filtros.tipo_fecha == 2"
                  :label="'Fecha Grupo 2'"
                  :fechas="[filtros.fecha_2.desde, filtros.fecha_2.hasta]"
                  @getFechas="getFechas2"
                  :icon="false"
                  hide-details="auto"
                ></dos-fechas>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn @click="exportExcel()" class="success mr-2" v-if="lista.length" small >ExportExcel</v-btn>
                <v-btn @click="cargar()" class="primary"> Buscar </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-select
              v-show="filtros.tipo_grupo === 2"
              dense
              outlined
              v-model="puestoSelect"
              :items="puestos"
              item-value="id"
              item-text="nombre"
              label="Area"
              hide-details="auto"
            ></v-select>
          </v-card-text>
          <v-card-text>
            <apexchart ref="grafricaRef" type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
            <v-simple-table dense fixed-header height="300px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th colspan="3" class="text-center">
                      Grupo 1
                      <br />
                      <small
                        >{{ filtros.fecha_1.desde }}
                        {{ filtros.tipo_fecha == 2 ? '/' + filtros.fecha_1.hasta : '' }}</small
                      >
                    </th>
                    <th></th>
                    <th colspan="3" class="text-center">
                      Grupo 2
                      <br />
                      <small
                        >{{ filtros.fecha_2.desde }}
                        {{ filtros.tipo_fecha == 2 ? '/' + filtros.fecha_2.hasta : '' }}</small
                      >
                    </th>
                  </tr>
                  <tr>
                    <th class="text-right">Total Venta</th>
                    <th class="text-center">Comandas</th>
                    <th class="text-center">Items</th>
                    <th class="text-center">Hora</th>
                    <th class="text-center">Items</th>
                    <th class="text-center">Comandas</th>
                    <th class="text-right">Total Venta</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in lista" :key="index">
                    <td class="text-right">
                      <span class="info--text">
                        $ {{ FuncionesGenerales.formatoNumeric(item.total_bruto_grupo_1) }}</span
                      >
                    </td>
                    <td class="text-center">
                      <span class="info--text"> {{ item.comanda_grupo_1 }}</span>
                    </td>
                    <td class="text-center">
                      <span class="info--text"> {{ item.items_grupo_1 }}</span>
                    </td>
                    <td class="text-center">{{ item.hora }}</td>
                    <td class="text-center">
                      <span class="success--text">{{ item.items_grupo_2 }}</span>
                    </td>
                    <td class="text-center">
                      <span class="success--text">{{ item.comanda_grupo_2 }}</span>
                    </td>
                    <td class="text-right">
                      <span class="success--text">
                        $ {{ FuncionesGenerales.formatoNumeric(item.total_bruto_grupo_2) }}</span
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import DosFechas from '@/components/DosFechas.vue'
import DatePicker from '@/components/DatePicker.vue'
import FuncionesGenerales from '@/funciones/funciones'
import { ref, watch } from '@vue/composition-api'
import moment from 'moment'
import ComandaService from '@/api/servicios/ComandaService'
// demos

export default {
  watch: {
    'filtros.tipo_fecha': function (news, olds) {
      if (news == 1) {
        this.filtros.fecha_1 = {
          desde: new moment().format('YYYY-MM'),
          hasta: new moment().format('YYYY-MM'),
        }

        this.filtros.fecha_2 = {
          desde: new moment().format('YYYY-MM'),
          hasta: new moment().format('YYYY-MM'),
        }
      } else {
        this.filtros.fecha_1 = {
          desde: new moment().format('YYYY-MM-DD'),
          hasta: new moment().format('YYYY-MM-DD'),
        }

        this.filtros.fecha_2 = {
          desde: new moment().format('YYYY-MM-DD'),
          hasta: new moment().format('YYYY-MM-DD'),
        }
      }
    },
  },
  components: { DosFechas, DatePicker },
  setup() {
    const grafricaRef = ref(null)
    const chartOptions = ref({
      chart: {
        height: 350,
        type: 'bar',
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],

      xaxis: {
        categories: ['06', '07'],
      },
      legend: {
        position: 'right',
        offsetY: 40,
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val + ' $'
              },
            },
          },
        ],
      },
      grid: {
        borderColor: '#f1f1f1',
      },
    })
    const series = ref([
      {
        name: 'Grupo 1',
        data: [],
      },
    ])
    const filtros = ref({
      tipo_fecha: 1,
      tipo_grupo: 1,
      fecha_1: {
        desde: new moment().format('YYYY-MM'),
        hasta: new moment().format('YYYY-MM'),
      },
      fecha_2: {
        desde: new moment().format('YYYY-MM'),
        hasta: new moment().format('YYYY-MM'),
      },
    })
    const lista = ref([])
    const cargando = ref(false)
    const cargar = () => {
      if (filtros.value.tipo_grupo == 1) {
        cargarDatos()
      } else {
        cargarDatosPuesto()
      }
    }
    const exportExcel = () => {
      let dato = {
        nombreDocumento:
          'VENTAS POR HORAS G1' +
          filtros.value.fecha_1.desde +
          (filtros.value.tipo_fecha == 2 ? ' al ' + filtros.value.fecha_1.hasta : '') +
          '/ G2' +
          filtros.value.fecha_2.desde +
          (filtros.value.tipo_fecha == 2 ? ' al ' + filtros.value.fecha_2.hasta : ''),
        nombreHoja: 'hoja1',
        cabecera: [],
        rows: [],
        anchoColumanas: [],
      }

      dato.cabecera = ['Total Venta', 'Comandas', 'Items', 'Hora', 'Items', 'Comandas', 'Total Venta']
      lista.value.forEach((item, i) => {
        let row = [
          {
            v: item.total_bruto_grupo_1.toFixed(2),
            t: 'n',
            s: {
              numFmt: '0.00',
            },
          },
          {
            v: item.comanda_grupo_1,
            t: 'n',
            s: {
              numFmt: '0',
            },
          },
          {
            v: item.items_grupo_1,
            t: 'n',
            s: {
              numFmt: '0',
            },
          },

          {
            v: item.hora,
            t: 'n',
            s: {
              numFmt: '0',
            },
          },
          {
            v: item.items_grupo_2,
            t: 'n',
            s: {
              numFmt: '0',
            },
          },
          {
            v: item.comanda_grupo_2,
            t: 'n',
            s: {
              numFmt: '0',
            },
          },
          {
            v: item.total_bruto_grupo_2.toFixed(2),
            t: 'n',
            s: {
              numFmt: '0.00',
            },
          },
        ]
        dato.rows.push(row)
      })

      FuncionesGenerales.exportarDataExcel(dato)
    }
    const cargarDatos = () => {
      cargando.value = true
      let xaxisTemp = []
      let seriesTemp1 = []
      let seriesTemp2 = []
      let filt = JSON.parse(JSON.stringify(filtros.value))
      if (filtros.value.tipo_fecha == 1) {
        filt = {
          ...filtros.value,
          fecha_1: {
            desde: filtros.value.fecha_1.desde + '-01',
            hasta: filtros.value.fecha_1.desde + '-01',
          },
          fecha_2: {
            desde: filtros.value.fecha_2.desde + '-01',
            hasta: filtros.value.fecha_2.desde + '-01',
          },
        }
      }
      ComandaService.reporteVentasHoraListar(filt)
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            lista.value = response.data.datos
            lista.value.forEach(item => {
              xaxisTemp.push(item.hora)
              seriesTemp1.push(item.total_bruto_grupo_1)
              seriesTemp2.push(item.total_bruto_grupo_2)
            })

            grafricaRef.value.updateOptions({
              xaxis: {
                categories: xaxisTemp,
              },
            })

            grafricaRef.value.updateSeries([
              {
                name:   filtros.value.fecha_1.desde +   (filtros.value.tipo_fecha == 2 ? '/' + filtros.value.fecha_1.hasta : '') ,
                data: seriesTemp1,
              },

              {
                name: filtros.value.fecha_2.desde +   (filtros.value.tipo_fecha == 2 ? '/' + filtros.value.fecha_2.hasta : ''),
                data: seriesTemp2,
              },
            ])
          }
        })
        .catch(error => {})
        .finally(() => {
          cargando.value = false
        })
    }
    const puestos = ref([])
    const puestoSelect = ref(null)

    watch(puestoSelect, () => {
      let xaxisTemp = []
      let seriesTemp1 = []
      let seriesTemp2 = []
      lista.value = puestos.value.find(item => item.id == puestoSelect.value).datos
      if (lista.value.length > 0) {
        lista.value.forEach(item => {
          xaxisTemp.push(item.hora)
          seriesTemp1.push(item.total_bruto_grupo_1)
          seriesTemp2.push(item.total_bruto_grupo_2)
        })
        grafricaRef.value.updateOptions({
          xaxis: {
            categories: xaxisTemp,
          },
        })

        grafricaRef.value.updateSeries([
          {
            name: 'Grupo1',
            data: seriesTemp1,
          },

          {
            name: 'Grupo2',
            data: seriesTemp2,
          },
        ])
      }
    })

    const cargarDatosPuesto = () => {
      cargando.value = true
      let xaxisTemp = []
      let seriesTemp1 = []
      let seriesTemp2 = []
      let filt = JSON.parse(JSON.stringify(filtros.value))
      if (filtros.value.tipo_fecha == 1) {
        filt = {
          ...filtros.value,
          fecha_1: {
            desde: filtros.value.fecha_1.desde + '-01',
            hasta: filtros.value.fecha_1.desde + '-01',
          },
          fecha_2: {
            desde: filtros.value.fecha_2.desde + '-01',
            hasta: filtros.value.fecha_2.desde + '-01',
          },
        }
      }
      ComandaService.reporteVentasHoraPuestoListar(filt)
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            puestos.value = response.data.datos

            lista.value = response.data.datos[0].datos
            puestoSelect.value = response.data.datos[0].id

            lista.value.forEach(item => {
              xaxisTemp.push(item.hora)
              seriesTemp1.push(item.total_bruto_grupo_1)
              seriesTemp2.push(item.total_bruto_grupo_2)
            })

            grafricaRef.value.updateOptions({
              xaxis: {
                categories: xaxisTemp,
              },
            })

            grafricaRef.value.updateSeries([
              {
                name: 'Grupo1',
                data: seriesTemp1,
              },

              {
                name: 'Grupo2',
                data: seriesTemp2,
              },
            ])
          }
        })
        .catch(error => {})
        .finally(() => {
          cargando.value = false
        })
    }
    const selectTipoFecha = [
      { Id: 1, Tipo: 'Meses' },
      { Id: 2, Tipo: 'Fechas' },
    ]
    const selectTipoGrupo = [
      { Id: 1, Tipo: 'Totales' },
      { Id: 2, Tipo: 'Por Area' },
    ]
    const getFechas1 = item => {
     
      filtros.value.fecha_1.desde = item.desde
      filtros.value.fecha_1.hasta = item.hasta
    }
    const getFechas2 = item => {
       
      filtros.value.fecha_2.desde = item.desde
      filtros.value.fecha_2.hasta = item.hasta
    }
    return {
      filtros,
      FuncionesGenerales,
      getFechas1,
      getFechas2,
      selectTipoFecha,
      selectTipoGrupo,
      cargarDatos,
      lista,
      grafricaRef,
      chartOptions,
      series,
      cargarDatosPuesto,
      cargar,
      puestos,
      puestoSelect,
      exportExcel
    }
  },
}
</script>
